* {
  box-sizing: border-box;
}

:root {
  --primary-color: #4c9b9b;
  --secondary-color: #63c179;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  line-height: 24px;
}

hr {
  border: 0;
  border-top: 1px solid #dedede;
  width: 100%;
}
